import React, { Component } from 'react';
import SideMenu from '../admin/SideMenu';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import TopMenu from '../admin/TopBar';
import UploadImage from '../utils/UploadImage';
import { database } from '../../base';
import { ref, onValue, push, set, remove } from 'firebase/database';
import { Toast, ErrorMessage, WarningMessage } from '../utils/HelpfulFunction';
import '../../styles/css/AdminMain.css';
import BasicDropzone from '../utils/Dropzone';

class SetUpBingoSquares extends Component {
    constructor(props) {
        super(props);
        this.UploadImage = new UploadImage();
        this.state = {
            itemsList: [],
            imageUrl: null,
            modal: false,
            image: "",
            name: "",
            emailImagePreview: {},
            checkedImagePreview: {},
            loading: true,
            files: [],
            statsOptions: [],
            selectedStat: null,
            selectedPlayerStat: null,
            amount: null
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.setState({ loading: true });

        const itemsListRef = ref(database, 'bingoCardsList');
        onValue(itemsListRef, (snapshot) => {
            const itemsList = snapshot.val() ? Object.values(snapshot.val()) : [];
            this.setState({
                itemsList,
                loading: false
            });
        });

        if (process.env.REACT_APP_STAT_FEED !== "") {
            this.getStatsFromFunctions();
        }
    }

    async getStatsFromFunctions() {
        let url = `https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/api/getStatsFromGame`;
        if (process.env.NODE_ENV === "development") {
            url = `http://localhost:5001/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/us-central1/api/getStatsFromGame`;
        }
        const bearerToken = await this.props.currentUser.getIdToken();
        const bearerTokenString = "Bearer " + bearerToken;
        let fetchObject = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': bearerTokenString
            },
            body: JSON.stringify({ statFeedType: process.env.REACT_APP_STAT_FEED })
        };
        let response;
        try {
            response = await fetch(url, fetchObject);
            response = await response.json();
            if (response && response.results) {
                this.setState({
                    statsOptions: response.results
                });
            }
        } catch (e) {
            console.log("ERROR: ", e);
        }
    }

    onDrop(files, rejected, myArgument) {
        if (rejected.length > 0) {
            ErrorMessage.fire({
                title: 'Image cannot be uploaded',
                text: 'Make sure the image is less than 2mbs and it is an accepted file type'
            });
            return;
        }
        this.setState({ loading: true });

        this.UploadImage.upload_file(files[0]).then(res => {
            this.setState({ loading: false });
            if (res.error) {
                ErrorMessage.fire({
                    title: 'Image cannot be uploaded',
                    text: res.error,
                });
            } else if (res.imageUrl) {
                const nameToUpdate = myArgument + "Preview";
                const fileToUpdate = files[0];
                this.setState({
                    [myArgument]: res.imageUrl,
                    [nameToUpdate]: {
                        fileToUpdate,
                        preview: URL.createObjectURL(fileToUpdate)
                    }
                });
            } else {
                ErrorMessage.fire({
                    title: 'Image cannot be uploaded',
                    text: 'Something went wrong, please re-upload your image and try again!'
                });
            }
        });
    }

    componentWillUnmount() {
        // Make sure to revoke the data uris to avoid memory leaks
        URL.revokeObjectURL(this.state.emailImagePreview.preview);
    }

    handleChange(event) {
        const { name, type, checked, value } = event.target;

        let result;
        if (type === 'checkbox') {
            result = checked;
        } else if (type === 'radio' && (value === "true" || value === "false")) {
            result = value === "true"; // will be true for "true" and false for "false"
        } else {
            result = value;
        }

        this.setState({ [name]: result });
    }

    handleSubmit(event) {
        event.preventDefault();
        if (this.state.loading) return;

        const name = this.state.name || "";
        if (name.length > 50) {
            ErrorMessage.fire({
                title: 'Oh uh!',
                text: 'Reward name cannot be longer than 50 characters!',
            });
            return;
        }
        const emailImage = this.state.emailImage || this.state.editTicketEmailImage;
        const checkedImage = this.state.checkedImage || this.state.editCheckedImage;
        const selectedStat = this.state.selectedStat;
        const selectedPlayerStat = this.state.selectedPlayerStat;
        const amount = this.state.amount;
        const vm = this;
        if (selectedStat && selectedStat.type === 'number' && !amount) {
            ErrorMessage.fire({
                title: 'Not all fields filled out!',
                text: 'Please enter them and try again'
            });
            return;
        } else if (selectedStat && selectedStat.type === 'player' && (!amount || !selectedPlayerStat)) {
            ErrorMessage.fire({
                title: 'Not all fields filled out!',
                text: 'Please enter them and try again'
            });
            return;
        }

        if (!name || !emailImage) {
            ErrorMessage.fire({
                title: 'Not all fields filled out!',
                text: 'Please enter them and try again'
            });
            return;
        }
        const itemToSave = {};
        itemToSave['name'] = name;
        itemToSave['image'] = emailImage;
        itemToSave['checkedImage'] = checkedImage || null;
        itemToSave['selectedStat'] = selectedStat || null;
        itemToSave['selectedPlayerStat'] = selectedPlayerStat || null;
        if (!selectedStat) {
            itemToSave['amount'] = null;
            itemToSave['selectedPlayerStat'] = null;
        } else {
            itemToSave['amount'] = amount;
        }
        let itemId = push(ref(database, 'bingoCardsList')).key;
        if (this.state.itemToEdit && (this.state.itemToEdit.id || this.state.itemToEdit.key)) {
            itemId = this.state.itemToEdit.id || this.state.itemToEdit.key;
        }
        itemToSave['id'] = itemId;
        this.setState({ loading: true });
        set(ref(database, `bingoCardsList/${itemId}`), itemToSave).then(() => {
            vm.setState({
                fileEmailImage: null,
                emailImage: null,
                checkedImage: null,
                name: "",
                loading: false,
                modal: false
            });
            Toast.fire({
                title: vm.state.itemToEdit ? 'Successfully Edited!' : 'Successfully Created!'
            });
        }).catch((err) => {
            vm.setState({ loading: false });
            ErrorMessage.fire({
                title: 'There was some error!',
                text: 'Try again and if the problem persists try logging out and logging back in'
            });
            console.log(err);
        });
    }

    async deleteTicket(e) {
        e.preventDefault();
        const array = this.state.itemsList;
        const index = array[e.target.value];
        const response = await WarningMessage.fire({
            title: 'Delete?',
            text: 'Are you sure you want to do this? You will no longer be able to use this in any new game',
            confirmButtonText: 'Delete'
        });
        if (response.value) {
            remove(ref(database, `bingoCardsList/${index.key || index.id}`)).then(() => {
                console.log('Item deleted successfully');
            }).catch((err) => {
                ErrorMessage.fire({
                    title: 'There was some error!',
                    text: 'Try again and if the problem persists try logging out and logging back in',
                });
                console.log(err);
            });
        }
    }

    editTicket(event) {
        event.preventDefault();
        const array = this.state.itemsList;
        const item = array[event.target.value];
        const stateToSet = {
            modal: true,
            name: item.name,
            amount: item.amount,
            itemToEdit: item,
            editCheckedImage: item.checkedImage,
            editTicketEmailImage: item.image,
            editingItem: true
        };
        if (item.selectedStat) {
            stateToSet['selectedStat'] = item.selectedStat;
        }
        if (item.selectedPlayerStat) {
            stateToSet['selectedPlayerStat'] = item.selectedPlayerStat;
        }
        this.setState(stateToSet);
    }

    toggle() {
        this.setState({
            modal: !this.state.modal,
            image: "",
            name: "",
            emailImagePreview: {},
            checkedImagePreview: {},
            checkedImage: "",
            editingItem: false,
            selectedStat: null,
            selectedPlayerStat: null,
            itemToEdit: null
        });
    }

    render() {
        const itemsList = this.state.itemsList;
        const emailImagePreview = this.state.emailImagePreview;
        const checkedImagePreview = this.state.checkedImagePreview;
        let showNumberAmount = false;
        let showBooleanAmount = false;
        let showPlayerAmount = false;
        const selectedStatObject = this.state.statsOptions.find(stat => stat.id.toString() === this.state.selectedStat);
        if (selectedStatObject && selectedStatObject.type === 'player') {
            showPlayerAmount = true;
        } else if (selectedStatObject && selectedStatObject.type === 'number') {
            showNumberAmount = true;
        } else if (selectedStatObject && selectedStatObject.type === 'boolean') {
            showBooleanAmount = true;
        }

        return (
            <div className="admin-wrapper">
                <div className="loading-screen" style={{ display: this.state.loading ? 'block' : 'none' }} />
                <SideMenu />
                <TopMenu />
                <div className="admin-main-panel">
                    <div className="card">
                        <div className="card-body">
                            <p className="admin-header-text" style={{ marginBottom: 0 }}>Bingo Cards (176px X 176px)</p>
                            <p className="admin-subheader-text">These are bingo cards you can set games up with</p>
                            <button className="btn btn-primary btn-lg create-prize-button" style={{ fontSize: 20, marginLeft: 20 }} onClick={this.toggle}>Add </button>
                            <div className="admin-grid-container four-columns" style={{ marginTop: 20 }}>
                                {
                                    itemsList.map(function (item, i) {
                                        return (
                                            <div key={i} className="card">
                                                <div className="card-body" align="center">
                                                    <p style={{ marginTop: 5 }}>{item.name}</p>
                                                    <p>
                                                        <img width="80%" height="auto" src={item.image} alt="" />
                                                    </p>
                                                    <button className="btn btn-primary btn-lg edit-button" style={{ marginRight: 5, marginBottom: 10 }} onClick={this.editTicket.bind(this)} value={i}><span className="fa fa-ellipsis-v" /> Edit</button>
                                                    <button className="btn btn-primary btn-lg delete-button" style={{ marginBottom: 10 }} onClick={this.deleteTicket.bind(this)} value={i}><span className="fa fa-trash-o" /> Delete</button>
                                                </div>
                                            </div>
                                        );
                                    }, this)
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <Modal isOpen={this.state.modal} toggle={this.toggle} style={{ width: '90%' }} id="myModal">
                    <div className="loading-screen" style={{ display: this.state.loading ? 'block' : 'none' }} />
                    <ModalHeader toggle={this.toggle}>
                        <div className="modal-title">{this.state.editingItem ? "Edit" : "Add"}</div>
                    </ModalHeader>
                    <ModalBody>
                        <div className="container-out">
                            <div className="question-box question-form">
                                <form className="pl-3 pr-3" onSubmit={this.handleSubmit} id="create-email-form">
                                    <div className="form-group">
                                        <label htmlFor="name">Name</label>
                                        <input id="name" name="name" type="text" className="form-control" value={this.state.name} onChange={this.handleChange} placeholder="Peanut Butter And Jelly" />
                                    </div>
                                    {this.state.statsOptions.length > 0 &&
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label htmlFor="stat">Choose Team Stat/Player</label>
                                                <select className="form-control" name="selectedStat" id="selectedStat" value={this.state.selectedStat} onChange={this.handleChange}>
                                                    <option />
                                                    {
                                                        this.state.statsOptions.map(function (item, index) {
                                                            if (item.type === 'playerStat') return null;
                                                            return (
                                                                <option value={item.id} key={index}>{item.name}</option>
                                                            );
                                                        })
                                                    }
                                                </select>
                                            </div>

                                            {showBooleanAmount &&
                                                <div className="col-md-4">
                                                    <label>Value</label>
                                                    <div>
                                                        <input
                                                            id="trueValue"
                                                            name="amount"
                                                            type="radio"
                                                            value={true}
                                                            checked={this.state.amount === true}
                                                            onChange={this.handleChange}
                                                        />
                                                        <label htmlFor="trueValue">True</label>
                                                    </div>
                                                    <div>
                                                        <input
                                                            id="falseValue"
                                                            name="amount"
                                                            type="radio"
                                                            value={false}
                                                            checked={this.state.amount === false}
                                                            onChange={this.handleChange}
                                                        />
                                                        <label htmlFor="falseValue">False</label>
                                                    </div>
                                                </div>
                                            }

                                            {showPlayerAmount &&
                                                <div className="col-md-4">
                                                    <label htmlFor="stat">Choose Stat</label>
                                                    <select className="form-control" name="selectedPlayerStat" id="selectedPlayerStat" value={this.state.selectedPlayerStat} onChange={this.handleChange}>
                                                        <option />
                                                        {
                                                            this.state.statsOptions.map(function (item, index) {
                                                                if (item.type !== 'playerStat') return null;
                                                                return (
                                                                    <option value={item.id} key={index}>{item.name}</option>
                                                                );
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            }

                                            {(showNumberAmount || showPlayerAmount) &&
                                                <div className="col-md-4">
                                                    <label htmlFor="amount">Amount</label>
                                                    <input id="amount" name="amount" type="number" className="form-control" value={this.state.amount} onChange={this.handleChange} placeholder="Enter amount" />
                                                </div>
                                            }
                                        </div>
                                    }
                                    <div className="form-group" align="center">
                                        <label htmlFor="rewardEmailImage" style={{ width: '100%' }}>
                                            To Mark Image
                                            <span style={{ cursor: "pointer", display: emailImagePreview.preview || this.state.editTicketEmailImage ? "" : "none" }} onClick={() => this.setState({ editTicketEmailImage: null, fileUploaded: false, emailImagePreview: {}, image: null })}>
                                                ❌
                                            </span>
                                        </label>
                                        <img src={this.state.editTicketEmailImage} width="auto" height="100px" style={{ display: this.state.editingItem ? '' : 'none' }} alt="" />
                                        <BasicDropzone
                                            onDrop={(acceptedFiles, fileRejections) => this.onDrop(acceptedFiles, fileRejections, "emailImage")}
                                            preview={emailImagePreview.preview}
                                        />
                                    </div>
                                    <div className="form-group" align="center">
                                        <label htmlFor="editCheckedImage" style={{ width: '100%' }}>
                                            Checked Image (Optional)
                                            <span style={{ cursor: "pointer", display: checkedImagePreview.preview || this.state.editCheckedImage ? "" : "none" }} onClick={() => this.setState({ editCheckedImage: null, fileUploaded: false, checkedImagePreview: {}, checkedImage: null })}>
                                                ❌
                                            </span>
                                        </label>
                                        <img src={this.state.editCheckedImage} width="auto" height="100px" style={{ display: this.state.editingItem ? '' : 'none' }} alt="" />
                                        <BasicDropzone
                                            onDrop={(acceptedFiles, fileRejections) => this.onDrop(acceptedFiles, fileRejections, "checkedImage")}
                                            preview={checkedImagePreview.preview}
                                        />
                                    </div>
                                    <div className="form-group text-center">
                                        <button className="btn btn-primary btn-lg submit-button" id="submitButton">Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

export default SetUpBingoSquares;
